//
// Custom styles for this frontend.
//



.p-multiselect-select-all .p-checkbox .p-checkbox-box {
  display: none;
}

.p-multiselect-checkbox .p-checkbox .p-checkbox-box {
  display: none;
}